import settings from '../../settings';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { CustomersData, CustomerQuestions } from '../models/Customers';
import { WebDataItem } from '../models/WebData';

const EpendisisApi: AxiosInstance = axios.create({
    baseURL: settings.Functions.EpendisisApi,
});


export const GetWebData = async (): Promise<WebDataItem[]> => {
    try {
        const data = {}
        const response: AxiosResponse<WebDataItem[]> = await EpendisisApi.get('/api/webdata/getWebData', data);
        return response.data;
    } catch (error: any) {
        return error?.response;
    }
}

export const updateWebData = async (token: string, data: WebDataItem[]): Promise<any> => {
    try {
        EpendisisApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response: AxiosResponse<any> = await EpendisisApi.put('/api/webdata/updateWebData', data);
        return response;
    } catch (error: any) {
        return error?.response;
    }
}
