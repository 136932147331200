import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import CarouselVideo from "../assets/carousel.mp4";
import logo from "../assets/logo-isolated.png";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import SummaryCard from "../components/SummaryCard/SummaryCard";
import HomePropertyCard from "../components/HomePropertyCard/HomePropertyCard";
import Footer from "../components/Footer/Footer";
import ContactUs from "../components/ContactUs/ContactUs";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "../styles/mobile/home.css";
import AccordionContainer from "../containers/AccordionContainer";
import FooterMobile from "../components/Footer/FooterMobile";
import HeaderMenuMobile from "../components/HeaderMenu/HeaderMenuMobile";
import StatsBanner from "../components/StatsBanner/StatsBanner";
import HomePropertySection from "../components/HomePropertySection/HomePropertySection";
import AccordionComponent from "../components/AccordionComponent/AccordionComponent";

function HomeMobile() {
  const accordionData = [
    {
      title: "Does an Enterprise subscription guarantee I’ll find a business I like?",
      content:
        "Yes, absolutely. An Enterprise subscription undoubtedly opens up a wealth of opportunities and benefits in the world of businesses. However, guaranteeing a perfect match between your preferences and an opportunity might be a bit ambitious. Let’s delve into what a Platinum subscription offers and what expectations to keep in mind.",
    },
    {
      title: "Can I cancel a Premium or Enterprise subscription?",
      content: "Yes, you can cancel your subscription at any time from your account settings.",
    },
    {
      title: "I already have a Premium subscription. Can I upgrade to Enterprise?",
      content: "Yes, upgrading from Premium to Enterprise is possible through your account settings.",
    },
    {
      title: "How does priority support work with an Enterprise subscription?",
      content: "Enterprise subscribers receive 24/7 priority customer support via email and chat.",
    },
    {
      title: "Do I need an Enterprise subscription to obtain more than one opportunity?",
      content: "No, but an Enterprise subscription provides access to unlimited opportunities.",
    },
  ];
  const statsData = [
    { value: "$100M +", label: "Transactions" },
    { value: "$1B +", label: "Investors AUM" },
    { value: "20 +", label: "Investors" },
    { value: "Free", label: "Consultation" },
  ];
  const [transition, setTransition] = useState(false);

  useEffect(() => {
    const logoElement = document.getElementById("fade-out-logo");
    const fadeInRow = document.getElementById("fade-in-row");
    if (!transition && logoElement) {
      setTimeout(() => logoElement.classList.add("fade-out"), 10);
      setTimeout(() => setTransition(true), 1500);
    }
    if (transition && fadeInRow) {
      setTimeout(() => fadeInRow.classList.add("fade-in"), 500);
    }
  }, [transition]);

  const videoStyle: React.CSSProperties = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    minHeight: "100vh",
  };

  return (
    <>
      <Container
        fluid
        className="wrapper"
        style={{
          flexDirection: "column",
          minHeight: "100vh",
          height: "100%",
          padding: 0,
        }}
      >
        <Row>
          <Col
            xs={12}
            md={6}
            style={{ position: "relative", width: "100%", minHeight: "100vh" }}
          >
            <video className="videoTag" autoPlay loop muted style={videoStyle}>
              <source src={CarouselVideo} type="video/mp4" />
            </video>

            {/* Logo on Mobile and Desktop */}
            {!transition && (
              <Row
                className="initial-logo-container"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  zIndex: 2,
                  width: "100%",
                  top: "40%",
                }}
              >
                <img
                  src={logo}
                  id="fade-out-logo"
                  style={{ height: "150px", width: "150px" }} // Adjusted for mobile
                  alt="Logo"
                />
              </Row>
            )}

            {/* Main Content */}
            <Row id="fade-in-row" style={{ position: "relative", zIndex: "2" }}>
              <Row className="d-none d-sm-flex justify-content-center">
                <HeaderMenu />
              </Row>
              <Row className="d-block d-sm-none justify-content-center">
                <HeaderMenuMobile />
              </Row>

              <Row
                style={{
                  width: "90%",
                  textAlign: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "20vh", // Adjust margin for mobile
                }}
              >
                {/* <Col className="text-wrap w-100">
                  <h3 className="gif-caption">BUSINESS DEALS</h3>
                </Col> */}
              </Row>

              <Row
                style={{
                  width: "85%",
                  textAlign: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "10px",
                }}
              >
                <Col>
                  <h4 className="gif-p">
                    Your Boutique Marketplace for
                    <br />
                    Private Equity and M&A Deals
                  </h4>
                </Col>
              </Row>

              <Row
                style={{
                  width: "85%",
                  textAlign: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "35px",
                  marginBottom: "100px", // Adjust margin for mobile
                }}
              >
                <Col>
                  <a
                    href="/marketplace"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      width: "auto",
                    }}
                  >
                    <Button
                      className="redefined-button-white"
                      style={{ height: "50px", width: "250px" }} // Adjusted for mobile
                    >
                      Go to the Marketplace
                    </Button>
                  </a>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
        {transition && (
          <>
            <div
              style={{
                position: "relative",
                width: "100%",
                marginTop: "-70px",
              }}
            >
              <StatsBanner stats={statsData} />
            </div>
            <Row
              style={{
                backgroundColor: "white",
                marginLeft: "4%",
                marginRight: "4%",
                marginTop: "4%",
              }}
            >
              <Col xs={12} className="platform-section">
                <h2 className="platform-title">What the platform does</h2>
              </Col>

              {/* Subcaption Section */}
              <Col xs={12} className="platform-text">
                <p>
                  A curated selection of deals tailored to your investment
                  preferences.
                </p>
              </Col>

              <Row
                style={{
                  marginTop: "2%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "flex",
                  justifyContent: "center",
                }}
                className="justify-content-center w-100"
              >
                {/* Buyers Summary Card */}
                <Col
                  xs={12}
                  className="d-flex justify-content-center"
                  style={{ marginBottom: "20px" }}
                >
                  <SummaryCard
                    title="For Buyers"
                    paragraphText="Discover the perfect deal to grow your portfolio or business and start due diligence effortlessly within minutes."
                    backgroundColor="#EBEBEB"
                    titleColor="#3553A2"
                    textColor="#444E71"
                  />
                </Col>

                {/* Marketplace Summary Card */}
                <Col
                  xs={12}
                  className="d-flex justify-content-center"
                  style={{ marginBottom: "20px" }}
                >
                  <SummaryCard
                    title="Marketplace"
                    paragraphText="We understand the importance of starting the right dialogue to foster a mutually beneficial transaction."
                    backgroundColor="#0F1931"
                    titleColor="#E0E8FF"
                    textColor="#FFFFFF"
                  />
                </Col>

                {/* Sellers Summary Card */}
                <Col
                  xs={12}
                  className="d-flex justify-content-center"
                  style={{ marginBottom: "20px" }}
                >
                  <SummaryCard
                    title="For Sellers"
                    paragraphText="Unlock the potential of crafting an impeccable listing with the guidance of our expert team."
                    backgroundColor="#B2C5F5"
                    titleColor="#3553A2"
                    textColor="#444E71"
                  />
                </Col>
                {/* Register Button */}
                <Col xs={12} className="register-container">
                  <button className="register-button">Register</button>
                </Col>
              </Row>
            </Row>
            <Row
              className="d-block d-sm-none justify-content-center"
              style={{ marginLeft: "2%", marginRight: "2%", marginTop: "4%" }}
            >
              <AccordionContainer />
            </Row>
            <Row>
              <HomePropertySection />
            </Row>
            <Row
              style={{
                backgroundColor: "white",
                marginLeft: "4%",
                marginRight: "4%",
                marginTop: "4%",
              }}
            >
              <Col xs={12} className="platform-section">
                <h2 className="home-quote">
                  “Being successful in private equity requires an unrelenting
                  commitment to transforming vision into reality.”
                </h2>
              </Col>

              {/* Subcaption Section */}
              <Col xs={12} className="platform-text">
                <p className="home-quote-paragraph">- Mitt Romney</p>
              </Col>
            </Row>
            <Row
              style={{
                backgroundColor: "#0F1931",
                marginTop: "4%",
                paddingTop: "75px",
                paddingBottom: "75px"
              }}
            >
              <Col
                xs={12}
                className="platform-section"
              >
                <h2
                  className="platform-title"
                  style={{ color: "white", fontSize: "45px" }}
                >
                  Example deals
                </h2>
              </Col>
              <Col xs={12} className="register-container">
                <button className="register-button" style={{ fontSize: "20px", maxWidth: "250px" }}>Go to Marketplace</button>
              </Col>
            </Row>
            <Row
              style={{
                backgroundColor: "white",
                paddingTop: "75px",
                paddingBottom: "75px",
                marginLeft: "auto",
                marginRight: "auto"
              }}
            >
              <Col
                xs={12}
                className="platform-section"
              >
                <h2
                  className="platform-title"
                  style={{
                    color: "#0F1931", fontSize: "45px", maxWidth: "500px",
                    width: "100%",
                    marginLeft: "auto",
                    marginRight: "auto"
                  }}
                >
                  Frequently Asked Questions
                </h2>
              </Col>
              <Col
                xs={12}
                className="platform-section"
                style={{ marginTop: "25px" }}
              >
                <AccordionComponent items={accordionData} className="enterprise-accordion" />
              </Col>
            </Row>
            <Row style={{
              backgroundColor: "#0F1931",
              paddingTop: "75px",
              paddingBottom: "75px",
              marginLeft: "auto",
              marginRight: "auto"
            }}>
              <Col
                xs={12}
                className="platform-section"
              >
                <h2
                  className="platform-title"
                  style={{
                    color: "white", fontSize: "45px", maxWidth: "1000px",
                    width: "100%",
                    marginLeft: "auto",
                    marginRight: "auto"
                  }}
                >
                  Join over 20,000 investors on ependisis.com and access a diverse range of opportunities.
                </h2>
              </Col>
              <Col style={{}}>
                <div style={{ marginLeft: "auto", marginRight: "auto", width: "170px", marginTop: "35px" }}>
                  <a
                    href="/marketplace"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      width: "auto",
                    }}
                  >
                    <Button
                      className="redefined-button-white"
                      style={{ height: "50px", width: "170px" }} // Adjusted for mobile
                    >
                      Join now
                    </Button>
                  </a>
                </div>
              </Col>
            </Row>
            {/* Subscribe Section */}
            <Row
              style={{
                backgroundColor: "white",
                paddingTop: "50px",
                paddingBottom: "50px",
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "center",
                marginTop:"30px"
              }}
            >
              {/* Heading */}
              <Col xs={12}>
                <h2 style={{
                  fontSize: "40px",
                  fontWeight: "bold",
                  fontFamily: "Helvetica-Neue, sans-serif",
                  color: "#0F1931",
                  marginBottom: "30px"
                }}>
                  Sign up for our exclusive newsletter.
                </h2>
              </Col>

              {/* Email Input Section */}
              <Col xs={12} style={{ display: "flex", justifyContent: "center" }}>
                <div style={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "20px",
                  paddingLeft: "50px",
                  height: "55px",
                  maxWidth: "800px",
                  width: "100%",
                  backgroundColor: "#F2F2F2",
                  boxShadow: "none"
                }}>

                  {/* SVG Email Icon */}
                  <span style={{ marginRight: "30px", color: "#333" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" viewBox="0 0 16 16" opacity="0.8">
                      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                    </svg>
                  </span>

                  {/* Input Field */}
                  <input
                    type="email"
                    placeholder="Enter your email address"
                    style={{
                      border: "none",
                      outline: "none",
                      background: "transparent",
                      flex: 1,
                      fontSize: "16px",
                      color: "#333"
                    }}
                  />

                  {/* Subscribe Button */}
                  <Button
                    className="subscribe-button-home"
                  >
                    Subscribe
                  </Button>
                </div>
              </Col>

              {/* Privacy Policy Text */}
              <Col xs={12} style={{ marginTop: "10px" }}>
                <p style={{
                  fontSize: "14px",
                  color: "#666",
                  fontStyle: "italic"
                }}>
                  We care about your data. Read our <a href="/privacy-policy" style={{ color: "#6C7EE1", textDecoration: "none" }}>privacy policy</a>.
                </p>
              </Col>
            </Row>

          </>
        )}
        {transition && (
          <Row
            style={{
              backgroundColor: "white",
              marginLeft: "4%",
              marginRight: "4%",
              marginTop: "7%",
            }}
          ></Row>
        )}
      </Container>
      <Row
        className="d-none d-sm-flex justify-content-center"
        style={{ marginTop: "1%" }}
      >
        <Footer />
      </Row>
      <Row
        className="d-block d-sm-none justify-content-center"
        style={{ marginLeft: "2%", marginRight: "2%", marginTop: "4%" }}
      >
        <FooterMobile />
      </Row>
    </>
  );
}

export default HomeMobile;
