import React from "react";
import { Accordion } from "react-bootstrap";
import './AccordionComponent.css';

interface AccordionItem {
    title: string;
    content: string;
  }
  
  interface AccordionComponentProps {
    items: AccordionItem[];
    className?: string;
  }
  
  const AccordionComponent: React.FC<AccordionComponentProps> = ({ items, className = "" }) => {
    return (
      <Accordion defaultActiveKey="0" flush className={`enterprise-accordion ${className}`}>
        {items.map((item, index) => (
          <Accordion.Item eventKey={index.toString()} key={index} className="custom-accordion-item">
            <Accordion.Header>{item.title}</Accordion.Header>
            <Accordion.Body className="custom-accordion-body">{item.content}</Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    );
  };
  
  export default AccordionComponent;
