import React, { ChangeEvent, useEffect, useState } from "react";
import { WebDataItem } from "../../models/WebData";

interface ChildProps {
  data: WebDataItem; // Ensure the prop name is wData
  onUpdate: (
    SECTION: string,
    CODE: string,
    TITLE: string,
    VALUE: string
  ) => void;
}

const WebSection: React.FC<ChildProps> = ({ data, onUpdate }) => {
  const [value, setValue] = useState(data.VALUE);
  const [title, setTitle] = useState(data.TITLE);

  function handleValueChange(event: ChangeEvent<HTMLInputElement>): void {
    setValue(event.target.value);
    onUpdate(data.SECTION, data.CODE, value, title);
  }

  function handleTitleChange(event: ChangeEvent<HTMLInputElement>): void {
    setTitle(event.target.value);
    onUpdate(data.SECTION, data.CODE, value, title);
  }

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)", // 4 columns
        gap: "10px",
      }}
    >
      <div
        key={data.ID}
        style={{
          display: "flex",
          flexDirection: "column",
          flex: "1 1 45%",
        }}
      >
        <label
          htmlFor={data.CODE}
          style={{ marginBottom: "5px", fontWeight: "bold" }}
        >
          {data.TAG}
        </label>
        <div
          key={data.ID}
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)", // 4 columns
            gap: "10px",
          }}
        >
          <input
            key={"title-" + data.CODE}
            type="text"
            value={data.TITLE || ""}
            onChange={handleTitleChange}
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              fontSize: "14px",
            }}
          />
          <input
            key={"value-" + data.CODE}
            type="text"
            value={data.VALUE || ""}
            onChange={handleValueChange}
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              fontSize: "14px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default WebSection;
