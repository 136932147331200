import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import "./StatsBanner.css"; // Add styles here

interface Item {
  value: string;
  label: string;
}

interface StatsBannerProps {
  stats: Item[];
}

const useAnimatedValues = (items: Item[], duration: number = 2000) => {
  const [animatedItems, setAnimatedItems] = useState<Item[]>([]);

  useEffect(() => {
    const startAnimation = (item: Item): Promise<Item> => {
      return new Promise((resolve) => {
        const match = item.value.match(/\d+/); // Find the first number
        if (!match) return resolve(item); // No number, return the original item

        const targetNumber = parseInt(match[0], 10); // Extract the number
        const startTime = performance.now();

        const animate = (currentTime: number) => {
          const elapsed = currentTime - startTime;
          const progress = Math.min(elapsed / duration, 1); // Clamp progress to [0, 1]
          const currentValue = Math.floor(progress * targetNumber);

          // Update animated value
          setAnimatedItems((prevItems) =>
            prevItems.map((i) =>
              i.label === item.label
                ? { ...i, value: item.value.replace(/\d+/, currentValue.toString()) }
                : i
            )
          );

          if (progress < 1) {
            requestAnimationFrame(animate);
          } else {
            resolve({
              ...item,
              value: item.value.replace(/\d+/, targetNumber.toString()),
            });
          }
        };

        requestAnimationFrame(animate);
      });
    };

    // Initialize with the original items
    setAnimatedItems(items);

    // Animate each item
    Promise.all(items.map(startAnimation)).then((finalItems) => {
      setAnimatedItems(finalItems); // Set final state
    });
  }, [items, duration]);

  return animatedItems;
};

const StatsBanner: React.FC<StatsBannerProps> = ({ stats }) => {
  
  const animatedItems = useAnimatedValues(stats); 

  return (
    <Row className="stats-banner">
      {animatedItems.map((stat, index) => (
        <Col key={index} xs={6} md={3} className="stat-item">
          <h3 className="stat-value">{stat.value}</h3>
          <p className="stat-label">{stat.label}</p>
        </Col>
      ))}
    </Row>
  );
};

export default StatsBanner;
