import React, { useEffect, useState } from "react";
import { GetWebData, updateWebData } from "../../api/Webdesign.Api";
import { RootState } from "../../../redux/Store";
import { useSelector } from "react-redux";
import { WebDataItem } from "../../models/WebData";

const WebdesignMainPage: React.FC = () => {
  const [webdata, setWebData] = useState<WebDataItem[]>();
  const loggedInUser = useSelector((state: RootState) => state.user);

  interface FormData {
    [key: string]: string;
  }

  // Custom labels for each field
  const customLabels = [
    "State 1 Title",
    "State 2 Title",
    "State 3 Title",
    "State 4 Title",
  ];
  const customLabels2 = [
    "State 1 Value",
    "State 2 Value",
    "State 3 Value",
    "State 4 Value",
  ];

  const [titleData, setTitleData] = useState<FormData>({});
  const [valueData, setValueData] = useState<{ [key: string]: string }>({});

  const [sections, setSections] = useState<string>();

  useEffect(() => {
    fetchData();
  }, []);

  // Simulate fetching data from an API
  const fetchData = async () => {
    try {
      const data = await GetWebData();
      setWebData(data);

      // Create a mapping from code to value
      const titleData: { [key: string]: string } = {};
      const valueData: { [key: string]: string } = {};
      data?.forEach((item) => {
        titleData[item.CODE] = item.TITLE; // Map code to value
        valueData[item.CODE] = item.VALUE; // Store title for later use

        setSections(item.SECTION); // Store title for later use
      });
      const x = sections;
      setTitleData(titleData);
      setValueData(valueData);
    } catch (error) {
      console.error("Error fetching web data:", error);
    }
  };

  // Update state when input values change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    //setWebData((prev) => ({ ...prev, [id]: value }));
  };

  return (
    <div
      style={{
        width: "100%",
        padding: "20px",
        fontFamily: "Arial, sans-serif",
        boxSizing: "border-box",
      }}
    >
      {/* Form Header */}
      <h1 style={{ textAlign: "left", marginBottom: "20px" }}>
        Web Configuration
      </h1>

      {/* Section 1 */}
      <div
        style={{
          marginBottom: "20px",
          border: "1px solid #ccc",
          padding: "20px",
          borderRadius: "8px",
        }}
      >
        <h2 style={{ fontSize: "20px", marginBottom: "20px" }}>
          Home Page State Bar
        </h2>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)", // 4 columns
            gap: "10px",
          }}
        >
          {Object.keys(titleData).map((code, index) => {
            //const label = fieldMapping[code]; // Use title as label
            return (
              <div
                key={code}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: "1 1 45%",
                }}
              >
                <label
                  htmlFor={code}
                  style={{ marginBottom: "5px", fontWeight: "bold" }}
                >
                  {customLabels[index]} {/* Use title as label */}
                </label>
                <input
                  id={code} // Use the code as the field's ID
                  type="text"
                  value={titleData[code] || ""} // Use the value from the API data
                  onChange={handleInputChange}
                  style={{
                    width: "100%",
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    fontSize: "14px",
                  }}
                />
              </div>
            );
          })}
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)", // 4 columns
            gap: "10px",
          }}
        >
          {Object.keys(valueData).map((code, index) => {
            //const label = fieldMapping[code]; // Use title as label
            return (
              <div
                key={code}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: "1 1 45%",
                }}
              >
                <label
                  htmlFor={code}
                  style={{ marginBottom: "5px", fontWeight: "bold" }}
                >
                  {customLabels2[index]} {/* Use title as label */}
                </label>
                <input
                  id={code} // Use the code as the field's ID
                  type="text"
                  value={valueData[code] || ""} // Use the value from the API data
                  onChange={handleInputChange}
                  style={{
                    width: "100%",
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    fontSize: "14px",
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
      {/* Save Button Container */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end", // Align to right
          alignItems: "center",
          marginTop: "40px",
          padding: "20px",
          border: "1px solid #ccc",
          borderRadius: "8px",
        }}
      >
        <p style={{ marginRight: "20px", marginTop: "10px" }}>
          By clicking save the changes will apply to the website!
        </p>
        <button
          style={{
            padding: "12px 60px",
            fontSize: "16px",
            fontWeight: "bold",
            color: "#fff",
            backgroundColor: "#007bff",
            border: "none",
            borderRadius: "6px",
            cursor: "pointer",
            transition: "background-color 0.3s",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
          onMouseOver={(e) =>
            (e.currentTarget.style.backgroundColor = "#0056b3")
          }
          onMouseOut={(e) =>
            (e.currentTarget.style.backgroundColor = "#007bff")
          }
          onClick={() => console.log("Saved data:")}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default WebdesignMainPage;
