import { Col } from "react-bootstrap";
import { SummaryCardProps } from "../../interfaces/components/SummaryCard/SummaryCardProps";
import "./SummaryCard.css";

const SummaryCard: React.FC<SummaryCardProps> = ({
    title,
    paragraphText,
    backgroundColor,
    titleColor,
    textColor
}) => {
    return (
        <Col
            className="summary-card"
            style={{
                backgroundColor: backgroundColor,
                color: textColor
            }}
        >
            <h3 className="summary-card-title" style={{ color: titleColor }}>
                {title}
            </h3>
            <p className="summary-card-text" style={{ color: textColor }}>
                {paragraphText}
            </p>
        </Col>
    );
}

export default SummaryCard;
