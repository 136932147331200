import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import PlanCardNewProps from "../../interfaces/components/PlanCard/PlanCardPropsNew";
import "./PlanCardNew.css";

function PlanCardNew({
  title,
  titleColor,
  backgroundColor,
  subtitle,
  buttonText,
  bulletList,
  buttonMethod,
}: PlanCardNewProps) {
  return (
    <Card
      className="plan-card-new"
      style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center", height: '100%', borderRadius: '8px', backgroundColor: backgroundColor, paddingLeft:'20px', paddingRight:'20px' }}
    >
      <Card.Body style={{ paddingLeft: "0", paddingRight: "0" }}>
        <Card.Title
          className="plan-card-title-new"
          style={{ color: titleColor, fontWeight: "bold" }}
        >
          {title.toUpperCase()}
        </Card.Title>
        <Card.Text className="plan-card-subtitle-new" style={{color:titleColor}}>{subtitle}</Card.Text>
        <Button onClick={buttonMethod} variant="outline-secondary" style={{color:backgroundColor, backgroundColor:titleColor}} className="plan-card-button-new">
          {buttonText}
        </Button>
        <ListGroup className="plan-card-bullet-list-new">
          {bulletList.map((item, index) => (
            <ListGroup.Item key={index} style={{border:0, padding:'0%', backgroundColor: backgroundColor}}>
              <div className="plan-card-list-group-contents-new" style={{backgroundColor: backgroundColor, color: titleColor}}>
                <div className="plan-card-bullet-list-title-new" style={{padding: 0}}>{item.title}</div>
                <ul className="plan-card-bullets-new">
                  {item.bullets.map((bullet, bulletIndex) => (
                    <li style={{color: titleColor, listStyleType: "none"}}  key={bulletIndex}>{bullet}</li>
                  ))}
                </ul>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
    </Card>
  );
}

export default PlanCardNew;
